import { Draft } from 'immer';
import { IADState, initialState } from './state';
import { AUTHENTICATION, ENABLE_INTEGRATION, EXECUTION, IAD_CANCEL_BTN_CLICK, IAD_SAVE_BTN_CLICK, REFRESH_CREDENTIALS, SETTINGS } from './action_types';

export const actionMap = {
  [AUTHENTICATION]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash[AUTHENTICATION] = {...draft.settingsHash[AUTHENTICATION], ...payload};
    draft.modified = true;
    draft.credsModified = true;
    draft.saved = false;
    draft.solutionInstanceUpdated = false;
  },
  [SETTINGS]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash[SETTINGS] = {...draft.settingsHash[SETTINGS], ...payload};
    if (payload.portal_id != null || payload.external_id != null) {
      draft.external_id = payload.portal_id || payload.external_id;
    }
    draft.modified = true;
  },
  [EXECUTION]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash[EXECUTION] = {...draft.settingsHash[EXECUTION], ...payload};
  },
  [REFRESH_CREDENTIALS]: (draft: Draft<IADState>, payload: any) => {
    const refreshKey = payload.tabName.charAt(0).toUpperCase() + payload.tabName.slice(1);
    draft.settingsHash[payload.tabName] = draft[`original${refreshKey}`];
  },
  [ENABLE_INTEGRATION]: (draft: Draft<IADState>, payload: any) => {
    draft.active = payload;
    draft.modified = true;
  },
  [IAD_CANCEL_BTN_CLICK]: (draft: Draft<IADState>) => {
    draft.settingsHash = {...initialState.settingsHash};
    draft.modified = false;
  },
  [IAD_SAVE_BTN_CLICK]: (draft: Draft<IADState>) => {
    draft.modified = false;
    draft.reloadFieldMappings = draft.credsModified;
    draft.credsModified = false;
    draft.saved = true;
    draft.createOrUpdate = 'update';
  }
};