import { Draft } from 'immer';
import {
  WDB_ADD_FIELD_MAPPING_ROW,
  WDB_ADD_SOLUTION_INSTANCE,
  WDB_ADD_SOURCE_TARGET,
  WDB_CHANGE_EMAIL_NOTIFICATIONS_ENABLED,
  WDB_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS,
  WDB_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS,
  WDB_DELETE_FIELD_MAPPING_ROW,
  WDB_DELETE_SOLUTION_INSTANCE,
  WDB_EMAIL_NOTIFICATIONS_ADD_ADDRESS,
  WDB_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK,
  WDB_EMAIL_NOTIFICATIONS_DELETE_ADDRESS,
  WDB_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK,
  WDB_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS,
  WDB_EXECUTE_SOLUTION_INSTANCE,
  WDB_LOAD_EXECUTIONS,
  WDB_LOAD_FIELD_MAPPING_OPTIONS,
  WDB_LOAD_SOLUTION_INSTANCES,
  WDB_PROVISION_SOLUTION_INSTANCE_ERROR,
  WDB_SAVE_SOLUTION_INSTANCE_OBJECTS,
  WDB_UPDATE_SOLUTION_INSTANCE,
  WDB_UPDATE_SOLUTION_INSTANCE_SCHEDULE,
  WDB_FILTER_FIELD_MAPPING_ROWS
} from './action_types';
import {IADState} from '../../../IntegrationApps/IntegrationAppDetails/state';

export const actionMap = {
  [WDB_ADD_FIELD_MAPPING_ROW]: (draft: Draft<IADState>, payload: any) => {
    const name = payload.instanceName;
    if (!draft.settingsHash['fieldMapping']) {
      draft.settingsHash['fieldMapping'] = { [name]: {} };
      draft.settingsHash['filteredFieldMapping'] = { [name]: {} };
    }

    if (!draft.settingsHash['fieldMapping'][name]) {
      draft.settingsHash['fieldMapping'][name] = {};
    }
    if (!draft.settingsHash['filteredFieldMapping'][name]) {
      draft.settingsHash['filteredFieldMapping'][name] = {};
    }

    if (!draft.settingsHash['fieldMapping'][name].mappings) {
      draft.settingsHash['fieldMapping'][name].mappings = {};
    }

    if (!draft.settingsHash['fieldMapping'][name].mappingRows) {
      draft.settingsHash['fieldMapping'][name].mappingRows = {};
      draft.settingsHash['filteredFieldMapping'][name].mappingRows = {};
    }

    draft.settingsHash['fieldMapping'][name].mappingRows[payload.rowId] = payload[name];
    draft.settingsHash['filteredFieldMapping'][name].mappingRows[payload.rowId] = payload[name];
   
    draft.modified = true;
    draft.solutionInstanceUpdated = false;
  },
  [WDB_DELETE_FIELD_MAPPING_ROW]: (draft: Draft<IADState>, payload: any) => {
    delete draft.settingsHash['fieldMapping'][payload.instanceName].mappingRows[payload.rowId];
    delete draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId];
    delete draft.settingsHash['filteredFieldMapping'][payload.instanceName].mappingRows[payload.rowId];
    delete draft.settingsHash['filteredFieldMapping'][payload.instanceName].mappings[payload.rowId];
    
    draft.modified = true;
    draft.solutionInstanceUpdated = false;
  },
  [WDB_ADD_SOURCE_TARGET]: (draft: Draft<IADState>, payload: any) => {
    const source = draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId]?.['source'];
    const target = draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId]?.['target'];
    draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId] = {
      source: payload.source || source,
      target: payload.target || target
    };
    
    draft.modified = true;
    draft.solutionInstanceUpdated = false;
  },
  [WDB_LOAD_SOLUTION_INSTANCES]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    const instanceIds = payload.map(e => e.id);
    draft.settingsHash['solutionInstances'] = draft.settingsHash['solutionInstances'].filter(e => instanceIds.includes(e.id));
    payload.forEach(si => {
      const idx = draft.settingsHash['solutionInstances'].findIndex(e => e.id === si.id);
      if (idx === -1) {
        draft.settingsHash['solutionInstances'].push(si);
      } else {
        draft.settingsHash['solutionInstances'][idx] = {
          ...draft.settingsHash['solutionInstances'][idx],
          ...si
        };
      }
    });
  },
  [WDB_DELETE_SOLUTION_INSTANCE]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash['solutionInstances'] = draft.settingsHash['solutionInstances'].filter(instance => instance.id != payload.id);
  },
  [WDB_ADD_SOLUTION_INSTANCE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    draft.settingsHash['solutionInstances']?.push(payload);
    draft.provisionSolutionInstanceError = false;
  },
  [WDB_UPDATE_SOLUTION_INSTANCE_SCHEDULE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    const instance = draft.settingsHash['solutionInstances'].find(instance => instance.id === payload.id);
    if (instance) {
      instance.scheduled_trigger = payload.scheduled_trigger;
      instance.interval = payload.interval;
      instance.timezone = payload.timezone;
      draft.solutionInstanceUpdated = true;
    }
  },
  [WDB_LOAD_EXECUTIONS]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash['executions'] = [...payload];
  },
  [WDB_LOAD_FIELD_MAPPING_OPTIONS]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['fieldMapping']) {
      draft.settingsHash['fieldMapping'] = {
        mappingOptions: {
          workdayFields: [],
          zuoraFields: []
        }
      };
    }
    draft.settingsHash['fieldMapping'].mappingOptions = {
      workdayFields: payload.workdayFields,
      zuoraFields: payload.zuoraFields
    };

    // regenerate mapping rows as mappingOptions change
    const keys = Object.keys(draft.settingsHash['fieldMapping']).filter(key => !['mappingOptions'].includes(key));
    keys.forEach(key => {
      const instance = draft.settingsHash['fieldMapping'][key];
      const rows = {};
      Object.keys(instance['mappings'] || {}).forEach(key => {
        rows[key] = {
          key,
          rowId: key,
          [`workday_target_fields`]: JSON.stringify(payload.workdayFields),
          '': '→',
          [`zuora_source_fields`]: JSON.stringify(payload.zuoraFields)
        }
      })
      instance.mappingRows = rows;
    });
    /**
     * sync with the fieldMapping state that is used in the table component
     * fieldMapping holds the filtered rows based on the search value
     */ 
    
    draft.settingsHash['filteredFieldMapping'] = draft.settingsHash['fieldMapping'];
  },
  [WDB_EXECUTE_SOLUTION_INSTANCE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['executions']) {
      draft.settingsHash['executions'] = [];
    }

    draft.settingsHash['executions'] = [...draft.settingsHash['executions'], payload];
  },
  [WDB_CHANGE_EMAIL_NOTIFICATIONS_ENABLED]: (draft: Draft<IADState>, enabled: boolean) => {
    if (!draft.settingsHash['emailNotifications']) {
      draft.settingsHash['emailNotifications'] = {};
      draft.settingsHash['emailNotifications']['enabled'] = false;
      draft.settingsHash['emailNotifications']['warnings'] = false;
      draft.settingsHash['emailNotifications']['success'] = false;
      draft.settingsHash['emailNotifications']['emails'] = [];
    }
    draft.settingsHash['emailNotifications']['enabled'] = enabled;
    draft.emailNotificationsModified = true;
  },
  [WDB_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS]: (draft: Draft<IADState>, warnings: boolean) => {
    draft.settingsHash['emailNotifications']['warnings'] = warnings;
    draft.emailNotificationsModified = true;
  },
  [WDB_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS]: (draft: Draft<IADState>, success: boolean) => {
    draft.settingsHash['emailNotifications']['success'] = success;
    draft.emailNotificationsModified = true;
  },
  [WDB_EMAIL_NOTIFICATIONS_ADD_ADDRESS]: (draft: Draft<IADState>) => {
    if (!draft.settingsHash['emailNotifications']['emails']) {
      draft.settingsHash['emailNotifications']['emails'] = [];
    }
    draft.settingsHash['emailNotifications']['emails'].push('');
    draft.emailNotificationsModified = true;
  },
  [WDB_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash['emailNotifications']['emails'][payload.index] = payload.email;
    draft.emailNotificationsModified = true;
  },
  [WDB_EMAIL_NOTIFICATIONS_DELETE_ADDRESS]: (draft: Draft<IADState>, index: number) => {
    draft.settingsHash['emailNotifications']['emails'].splice(index, 1);
    draft.emailNotificationsModified = true;
  },
  [WDB_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK]: (draft: Draft<IADState>) => {
    draft.settingsHash['emailNotifications'] = draft.originalEmailNotifications;
    draft.emailNotificationsModified = false;
  },
  [WDB_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK]: (draft: Draft<IADState>) => {
    draft.originalEmailNotifications = draft.settingsHash['emailNotifications'];
    draft.emailNotificationsModified = false;
  },
  [WDB_SAVE_SOLUTION_INSTANCE_OBJECTS]: (draft: Draft<IADState>, payload: any) => {
    const idx = draft.settingsHash['solutionInstances'].findIndex(si => si.id === payload.id);
    draft.settingsHash['solutionInstances'][idx] = {...payload};
  },
  [WDB_UPDATE_SOLUTION_INSTANCE]: (draft: Draft<IADState>) => {
    draft.solutionInstanceUpdated = true;
  },
  [WDB_PROVISION_SOLUTION_INSTANCE_ERROR]: (draft: Draft<IADState>) => {
    draft.provisionSolutionInstanceError = true;
  },
  [WDB_FILTER_FIELD_MAPPING_ROWS]: (draft: Draft<IADState>, payload: any) => {
    const name = payload.instanceName;
    const allMappings = draft.settingsHash['fieldMapping'][name]?.mappings;

    const allMappingRows = draft.settingsHash['fieldMapping'][name]?.mappingRows;
    const filteredRows = {};

    Object.keys(allMappings).forEach((key) => {
      const row = allMappings[key];

      if (row['source']['text']?.toLowerCase().indexOf(payload.filterValue.toLowerCase()) !== -1 || row['target']['text']?.toLowerCase().indexOf(payload.filterValue.toLowerCase()) !== -1) {
        filteredRows[key] = allMappingRows[key];
      }
    });
    
    draft.settingsHash['filteredFieldMapping'][name].mappingRows = filteredRows;
  }
};