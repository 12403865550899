import { Draft } from 'immer';
import { ImmerReducer } from 'use-immer';
import { IADState } from './state';
import { Action } from '../../Store';
import { actionMap as iadActionMap } from './action_map';
import { actionMap as nsActionMap } from '../../Connectors/CustomTabs/NetSuite';
import { actionMap as zdpActionMap } from '../../Connectors/CustomTabs/ZDP/action_map';
import { actionMap as wdActionMap } from '../../Connectors/CustomTabs/Workday';
import { actionMap as wdbActionMap } from '../../Connectors/CustomTabs/WorkdayBilling';
import { actionMap as zdqActionMap } from '../../Connectors/CustomTabs/ZDQ/action_map';

const actionMaps = {
  ...iadActionMap,
  ...nsActionMap,
  ...zdpActionMap,
  ...wdActionMap,
  ...wdbActionMap,
  ...zdqActionMap
};

export const reducer: ImmerReducer<IADState, Action> = (draft: Draft<IADState>, action: Action) => {
  const { type, payload } = action;

  // Note(Duc): this is for iframe and generic custom tabs
  if (type.match(/^iframe.*/i)?.input || type.match(/^custom.*/i)?.input) {
    actionMaps[type] = (draft: Draft<IADState>, payload: any) => {
      draft.settingsHash[type] = {...draft.settingsHash[type], ...payload};
      draft.modified = true;
    };
  }

  const mappedAction = actionMaps[type];

  if (mappedAction) {
    return mappedAction(draft, payload);
  } else {
    throw Error('Unknown action: ' + type);
  }
};