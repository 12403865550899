import { useState, useEffect } from 'react';
import Flipper from '../Connect/Flipper';

export const checkFlipper = async (featureKey, setFlipperValue) => {
    while (!window.hasOwnProperty('connect')) {
        await new Promise(resolve => setTimeout(resolve, 1000));
    }

    while (connect.tenant['connect_id'] == 'unknown' && connect.current_user['username'] == 'unknown' && connect.user_organization['id'] == 'unknown') {
        await new Promise(resolve => setTimeout(resolve, 1000));
    }

    try {
        let enabled = false;

        if (window.connect.current_user['id'] != 'unknown') {
            enabled = await Flipper.enabled(featureKey, 'User', window.connect.current_user['id']);
            if (enabled) {
                return setFlipperValue(true);
            }
        }

        if (window.connect.tenant['connect_id'] != 'unknown') {
            enabled = await Flipper.enabled(featureKey, 'Tenant', window.connect.tenant['connect_id']);
            if (enabled) {
                return setFlipperValue(true);
            }
        }

        if (window.connect.user_organization['id'] != 'unknown') {
            enabled = await Flipper.enabled(featureKey, 'Organization', window.connect.user_organization['id']);
            if (enabled) {
                return setFlipperValue(true);
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const useFlipperHook = (featureKey) => {
    const [featureEnabled, setFeatureEnabled] = useState(false);
    const [featureLoading, setFeatureLoading] = useState(true);

    useEffect(() => {
        checkFlipper(featureKey, setFeatureEnabled).then(() => setFeatureLoading(false));
    }, []);

    return [featureEnabled, featureLoading];
};