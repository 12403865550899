import React, { FC } from 'react';
import { Accordion, Card, Typography } from '@platform-ui/design-system';
import BasicTemplateHeader from './BasicTemplateHeader';
import BasicTemplateContent from './BasicTemplateContent';
import $ from 'jquery';

export interface BasicTemplateProps {
  id: string;
  showHeader?: boolean;
  header?: string;
  lastUpdatedBy?: string;
  lastUpdatedDate?: string;
  showTestConnection?: boolean;
  fieldGroups?: any;
  locale?: string;
  tabName?: string;
  showRefreshButton?: boolean;
}

const BasicTemplate: FC<BasicTemplateProps> = ({
  id,
  showHeader = true,
  header,
  lastUpdatedBy,
  lastUpdatedDate,
  showTestConnection = false,
  fieldGroups,
  locale,
  tabName,
  showRefreshButton = false
}: BasicTemplateProps) => {
  const firstFieldGroup = fieldGroups?.slice(0, 1) || [];
  const otherFieldGroups = fieldGroups?.slice(1) || [];

  const title = firstFieldGroup[0]?.titleTranslations?.[locale];
  const collapsible = firstFieldGroup[0]?.collapsible;
  const hidden = firstFieldGroup[0]?.hidden;
  const cardContent = <BasicTemplateContent fieldGroups={firstFieldGroup} locale={locale} tabName={tabName} />;

  // Remove padding from accordion
  $("<style>").text("div.MuiAccordionSummary-root { padding: 0px; }").appendTo("head");

  return (
    <>
      <Card
        id={id}
        e2e={`${id}-template`}
        header={
          showHeader && <BasicTemplateHeader
            header={header}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
            showTestConnection={showTestConnection}
            tabName={tabName}
            showRefreshButton={showRefreshButton}
          />
        }
        body={
          hidden
            ? null
            : collapsible
              ? <Accordion titleBar header={<Typography children={title} variant='title' />} body={cardContent} elevation={0} variant='fullWidth' />
              : cardContent
        }
      />
      {
        otherFieldGroups.map((fieldGroup, idx) => (
          <div style={{ marginTop: '10px' }}>
            <BasicTemplate
              id={`basic-template-field-group-${idx}`}
              showHeader={false}
              fieldGroups={[fieldGroup]}
              locale={locale}
              tabName={tabName}
            />
          </div>
        ))
      }
    </>
  );
};

export default BasicTemplate;